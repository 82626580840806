<template>
  <div>
    <section class="guideline main-version">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Main Version</h2>
              <div class="section-caption">
                <p>
                  The most used version on websites and printed 
  materials is the horizontal one.
                </p>
              </div>
            </div>
          </div>
          
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents">
            <b-img
            :src="require('@/assets/images/pages/guideline/main-version-1.jpg')"
            class="main-version__img img-fluid" />
          </div>          
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -blue">
            <b-img
            :src="require('@/assets/images/pages/guideline/main-version-2.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline vertical-version">
      <b-row>
        <b-col md="4" class="guideline__col -grey">
          <div class="guideline__col-contents">
            <div class="section-info">
              <h2 class="section-title">Vertical Version</h2>
              <div class="section-caption">
                <p>
                  This is meant for when we don’t have that 
  much horizontal space for the full name, 
  and when we want to use our own colors as 
  background. The symbol should be able to 
  communicate IOT, just as our shortname for 
  www.ieltsonlinetests.com.
                </p>
              </div>
            </div>
          </div>
          
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
              :src="require('@/assets/images/pages/guideline/vertical-version-1.jpg')"
              class="vertical-version__img img-fluid" />
            <b-img
              :src="require('@/assets/images/pages/guideline/vertical-version-2.jpg')"
              class="vertical-version__img img-fluid" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents">
            <b-img
            :src="require('@/assets/images/pages/guideline/vertical-version-3.jpg')"
            class="vertical-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline app-icon">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">App Icon</h2>
              <div class="section-caption">
                <p>
                  Just preseeing an app icon here :)
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents">
            <b-img
            :src="require('@/assets/images/pages/guideline/app-icon-1.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -blue">
            <b-img
            :src="require('@/assets/images/pages/guideline/app-icon-2.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>
    
    <section class="guideline logo-grid">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Grid</h2>
              <div class="section-caption">
                <p>
                  This is how the logo was drawn. There is some
sience behind it, so please do not attempt to
redesign it yourself. We from the digital team
at Intergreat will gladly provide the logo assets
whenever you need!
                </p>
                <p>digital@intergreat.com</p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents">
            <b-img
            :src="require('@/assets/images/pages/guideline/grid-1.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents">
            <b-img
            :src="require('@/assets/images/pages/guideline/grid-2.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline minimum-size">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Minimum Size</h2>
              <div class="section-caption">
                <p>
                  Minimum sizes allowed for the logotype, 
symbol and favicon are shown here.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/minimum-size-1.jpg')"
            class="main-version__img img-fluid mb-10" />
            <b-img
            :src="require('@/assets/images/pages/guideline/minimum-size-2.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column -blue">
            <b-img
            :src="require('@/assets/images/pages/guideline/minimum-size-3.jpg')"
            class="main-version__img img-fluid mb-10" />
            <b-img
            :src="require('@/assets/images/pages/guideline/minimum-size-4.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline safety-margin -grey">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Safety Margin</h2>
              <div class="section-caption">
                <p>
                  This is the margin around our logotype that 
allows it to read clearly together with others 
text or interface elements.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="8" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/safety-margin-1.jpg')"
            class="main-version__img img-fluid mb-5" />
            <b-img
            :src="require('@/assets/images/pages/guideline/safety-margin-2.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline monochoromatic">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Monochromatic</h2>
              <div class="section-caption">
                <p>
                  A preview of the logotype on monocromatic 
backgrounds, full black and white.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/monochrom-1.jpg')"
            class="main-version__img img-fluid mb-10" />
            <b-img
            :src="require('@/assets/images/pages/guideline/monochrom-2.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column -black">
            <b-img
            :src="require('@/assets/images/pages/guideline/monochrom-3.jpg')"
            class="main-version__img img-fluid mb-10" />
            <b-img
            :src="require('@/assets/images/pages/guideline/monochrom-4.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline monochoromatic">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">What not to do</h2>
              <div class="section-caption">
                <p>
                  Transforming the logo anyhow is just not cool 
at all. Check in this page some awful mistakes 
that we would be very sad to see.
We from the digital team at InterGreat will 
always gladly privide the assets whenever you 
need them.<br><br>
Email us anytime for anything! We will love to 
know where IOT logo is going to be used.
                </p>
                <p>
                  digital@intergreat.com
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/what-not-1.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/what-not-2.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/what-not-3.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/what-not-4.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/what-not-5.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/what-not-6.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline tone-voice">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title mb-0">Tone of voice</h2>
              <h3 class="section-sub-title">How you say things is 
just as important as 
what you are saying</h3>
              <div class="section-caption">
                <p>
                  There are three key principles that reveals 
IOT's tone of voice. These principles allow us 
to convey the brand's unique personality in 
our writing, and ensure consistency across all 
of our communications.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="8" class="guideline__col">
          <div class="guideline__col-contents flex-column flex-start">
            <div class="tone-voice__item">
              <h5 class="tone-voice__item-title">Compassionate and non-judgemental</h5>
              <div class="tone-voice__item-desc">We will always be focused on the international students who want to study abroad, and so the 
stories we tell should be with the IELTS exam situation at their heart.</div>
            </div>
            <div class="tone-voice__item">
              <h5 class="tone-voice__item-title">Friendly and practical</h5>
              <div class="tone-voice__item-desc">Our language should reflect our constant desire to do more for them. We know what works in 
real exams. We know what univerties want! We should aim to speak with simplicity and clarity in 
general, educating and very much enjoying it.</div>
            </div>
            <div class="tone-voice__item">
              <h5 class="tone-voice__item-title">Personable and helpful</h5>
              <div class="tone-voice__item-desc">This is how we aim to deliver our daily work so our communications should also reflect this. They 
should sound like they come from a person not an institution. We can use the pronoun ‘we’ when we 
talk on behalf of IOT. We should avoid being overly formal and use an active voice (“We’ll send you 
some information” rather than “some information will be sent to you”).</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline word-pool">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title mb-0">Word Pool</h2>
              <h3 class="section-sub-title">Always Repeating 
Them..</h3>
              <div class="section-caption">
                <p>
                  These are the main SEO words that we use 
in the website, and that are meant to be 
reused in communications materials.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="8" class="guideline__col">
          <div class="guideline__col-contents flex-column flex-start">
            <b-img
            :src="require('@/assets/images/pages/guideline/word-pool.jpg')"
            class="main-version__img img-fluid" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline use-imagery">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Use of Imagery</h2>
              <h3 class="section-sub-title">Illustrating branding 
and talking business.</h3>
              <div class="section-caption">
                <p>
                  Wherever possible the images should tell a 
story and have some context to help with this, 
looking as friendly and fun as possible, just 
like our brand! It should relate to the work we 
do directly, i.e. supporting study or indirectly if 
the image helps to tell a story. Avoid the use 
of people looking directly into the camera, and 
any other image that is not directly related to 
education
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-1.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-3.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-2.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-4.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline use-imagery">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Use of Imagery</h2>
              <h3 class="section-sub-title">Illustrating Social Media and General Content</h3>
              <div class="section-caption">
                <p>
                  These are oriented to illustrate and support 
headings of IELTS tips.
Tips that we frequently share with our online 
community via newsletters and on social 
media.
Try to avoid illustrations as much as possible 
because of their own colour scheme. Make use 
of images from photo stocks that InterGreat 
had purchased and that look as natural as 
possible. If it is to use illustrations, you must 
try to edit the colours to match our branding, 
just as the sample aside.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-5.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-6.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-7.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-8.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
      </b-row>
    </section>
    
    <section class="guideline use-imagery">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Use of Imagery</h2>
              <h3 class="section-sub-title">Illustrating IELTS Tips</h3>
              <div class="section-caption">
                <p>
                  These are oriented to illustrate and support 
headings of IELTS tips.
Tips that we frequently share with our online 
community via newsletters and on social 
media.
Try to avoid illustrations as much as possible 
because of their own colour scheme. Make use 
of images from photo stocks that InterGreat 
had purchased and that look as natural as 
possible. If it is to use illustrations, you must 
try to edit the colours to match our branding, 
just as the sample aside.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="8" class="guideline__col">
          <div class="guideline__col-contents">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-9.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline use-imagery">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Use of Imagery</h2>
              <h3 class="section-sub-title">Illustrating IELTS Tips</h3>
              <div class="section-caption">
                <p>
                  These are oriented to illustrate and support 
headings of IELTS tips.
Tips that we frequently share with our online 
community via newsletters and on social 
media.
Try to avoid illustrations as much as possible 
because of their own colour scheme. Make use 
of images from photo stocks that InterGreat 
had purchased and that look as natural as 
possible. If it is to use illustrations, you must 
try to edit the colours to match our branding, 
just as the sample aside.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="8" class="guideline__col">
          <div class="guideline__col-contents">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-10.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline use-imagery">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Use of Imagery</h2>
              <h3 class="section-sub-title">Illustrating IELTS Tips</h3>
              <div class="section-caption">
                <p>
                  These are oriented to illustrate and support 
headings of IELTS tips.
Tips that we frequently share with our online 
community via newsletters and on social 
media.
Try to avoid illustrations as much as possible 
because of their own colour scheme. Make use 
of images from photo stocks that InterGreat 
had purchased and that look as natural as 
possible. If it is to use illustrations, you must 
try to edit the colours to match our branding, 
just as the sample aside.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="8" class="guideline__col">
          <div class="guideline__col-contents">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-11.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline use-imagery">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Use of Imagery</h2>
              <h3 class="section-sub-title">Study Books Covers</h3>
              <div class="section-caption">
                <p>
                  These are oriented to illustrate and support 
headings of IELTS tips.
Tips that we frequently share with our online 
community via newsletters and on social 
media.
Try to avoid illustrations as much as possible 
because of their own colour scheme. Make use 
of images from photo stocks that InterGreat 
had purchased and that look as natural as 
possible. If it is to use illustrations, you must 
try to edit the colours to match our branding, 
just as the sample aside.
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-12.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-13.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-14.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/use-image-15.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
      </b-row>
    </section>

    <section class="guideline use-imagery">
      <b-row>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents -grey">
            <div class="section-info">
              <h2 class="section-title">Graphisms and Shapes</h2>
              <div class="section-caption">
                <p>
                  We can use pieces and bits of our symbol, 
filled with colours or outlined, when designing 
backgrounds to enfource our brand. Just aply 
them nicely within your grid, and it should 
all be fine! Also colourful filters on images 
that can support the context of what we are 
saying are very welcome.
                </p>
                <p>See some samples for backgrounds!</p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/graphisms-shape-1.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/graphisms-shape-3.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
        <b-col md="4" class="guideline__col">
          <div class="guideline__col-contents flex-column">
            <b-img
            :src="require('@/assets/images/pages/guideline/graphisms-shape-2.jpg')"
            class="main-version__img img-fluid mb-2" />
            <b-img
            :src="require('@/assets/images/pages/guideline/graphisms-shape-4.jpg')"
            class="main-version__img img-fluid mb-2" />
          </div>
        </b-col>
      </b-row>
    </section>
    
  </div>
</template>

<script>
  import { BCard, BAlert, BLink, BCardText, BRow, BCol, BImg } from 'bootstrap-vue'
  export default {
    components: {
      BCard,
      BAlert,
      BLink,
      BRow,
      BCol,
      BCardText,
      BImg
    },
  }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-guideline.scss';
</style>